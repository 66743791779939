import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  de: {
    translation: {
      app: {
        name: "ADM - Playbook",
      },
      sidebar: {
        consultations: "Beratungen",
        chapters: "Kapitel",
        overview: "Übersicht",
        categories: "Kategorien",
        news: "Neuigkeiten",
      },
      errors: {
        notFound: "Fehler: Nicht gefunden",
        server: "Server Fehler",
        err404: "404: Die von Ihnen gesuchte Seite ist nicht vorhanden",
        err500: "500: Interner Serverfehler",
        shady: `
		Entweder haben Sie eine zwielichtige Route versucht oder Sie sind aus Versehen hierher gekommen.
		In jedem Fall sollten Sie versuchen, die Navigation zu benutzen.
		  `,
      },
      consultations: {
        page: "Beratungen",
        hello: "Guten Morgen, {{name}}",
        table: {
          fields: {
            date: {
              name: "Datum",
              description: "Datum des Termins.",
            },
            consultant: {
              name: "Berater",
              description: "Berater E-Mail.",
            },
            customer: {
              name: "Kunde",
              description: "Kunden E-Mail.",
            },
            company: {
              name: "Unternehmen",
              description: "Unternehmen des Kunden.",
            },
            rating: {
              name: "Bewertung",
              description: "Bewertung der Beratung.",
            },
            action: {
              name: "eDialog",
              description: "eDialog URL.",
            },
          },
        },
      },
      consultationDetail: {
        page: "Details zur Beratung",
        tabs: {
          consultation: "Beratung",
          chapters: "Kapitel",
          consultant: "Berater",
          customer: "Kunde",
        },
      },
      backToHome: "Zurück zu Startseite",
      login: "Anmelden",
      loginMsg: "",
      logout: "Ausloggen",
      actions: {
        open: "Öffnen",
        close: "Schließen",
        remove: "Entfernen",
        edit: "Bearbeiten",
        update: "Aktualisieren",
        yes: "Ja",
        no: "Nein",
        abort: "Abbrechen",
      },
      components: {
        contactDetails: {
          cardName: "Kontakt Details",
          email: "E-Mail",
          firstname: "Vorname",
          lastname: "Nachname",
          salutation: "Anrede",
          company: "Unternehmen",
        },
        consultingDetails: {
          name: "Beratung",
          appointment: "Termin",
          appointmentPlace: "Ort",
          nextAppointment: "Nächster Termin",
          nextAppointmentPlace: "Nächster Ort",
          topic: "Thema",
          rating: "Bewertung",
        },
        chapterList: {
          page: {
            name: "Kapitel",
          },
          table: {
            id: {
              name: "Kapitel ID",
              description: "Kapitel ID",
            },
            name: {
              name: "Name",
              description: "Kapitel Name",
            },
            key: {
              name: "Schlüssel",
              description: "Kapitel Schlüssel",
            },
            enabled: {
              name: "Freigegeben",
              description: "Kapitel aktiviert oder nicht",
            },
            path: {
              name: "Pfad",
              description: "Pfad des Kapitels",
            },
            action: {
              name: "Aktion",
              description: "Kapitel entfernen",
            },
          },
          form: {
            name: {
              name: "Name des Kapitels",
              description: "Name des Kapitels",
              required: "Name des Kapitels ist erforderlich",
            },
            path: {
              name: "Pfad zum Kapitel",
              required: "Pfad ist erforderlich",
              error: "Geben Sie einen gültigen Pfad ein",
            },
            key: {
              name: "Kapitel Schlüssel",
              required: "Schlüssel ist erforderlich",
              error: "Gültigen Schlüssel eingeben",
            },
            enabled: {
              name: "Kapitel aktiviert",
            },
            category: {
              name: "Kategorie des Kapitels",
              required: "Kategorie ist erforderlich",
              error: "Kategorie wählen",
            },
            add: "Kapitel hinzufügen",
          },
        },
        settingsDrawer: {
          name: "Einstellungen",
          textDirection: "Textrichtung ändern",
          rtl: "RTL",
          ltr: "LTR",
          responsiveFontSizes: "Anpassbare Schriftgrößen",
          adjustForSmallDevices: "Adjust font for small devices",
          compact: "Kompakt",
          fixedWidth: "Feste Breite auf einigen Bildschirmen",
          roundCorners: "Abgerundete Ecken",
          increaseBorderRadius: "Randradius vergrößern",
          save: "Einstellungen speichern",
        },
        categoriesList: {
          page: {
            name: "Kategorien",
          },
          table: {
            id: {
              name: "Kategorie ID",
              description: "Kategorie ID",
            },
            name: {
              name: "Name",
              description: "Kategorie Name",
            },
            key: {
              name: "Schlüssel",
              description: "Kategorie Schlüssel",
            },
            action: {
              name: "Aktion",
              description: "Kategorie entfernen",
            },
          },
          form: {
            name: {
              name: "Name der Kategorie",
              description: "Name der Kategorie",
              required: "Name der Kategorie ist erforderlich",
            },
            key: {
              name: "Kategorie Schlüssel",
              required: "Schlüssel ist erforderlich",
              error: "Gültigen Schlüssel eingeben",
            },
            add: "Kategorie hinzufügen",
          },
        },
        newsList: {
          page: {
            name: "Neuigkeiten",
          },
          table: {
            id: {
              name: "Nachrichten ID",
              description: "Nachrichten ID",
            },
            author: {
              name: "Verfasser",
              description: "Verfasser der Nachricht",
            },
            title: {
              name: "Titel",
              description: "Titel der Nachricht",
            },
            created_at: {
              name: "Erstellt am",
              description: "Datum der Erstellung",
            },
            action: {
              name: "Aktion",
              description: "Aktionen",
            },
          },
          form: {
            author: {
              name: "Verfasser der Nachricht",
              required: "Verfasser ist erforderlich",
            },
            title: {
              name: "Titel",
              required: "Titel ist erforderlich",
            },
            content: {
              name: "Inhalt",
              required: "Inhalt ist erforderlich",
              placeholder: "Nachricht verfassen...",
            },
            add: "Nachricht hinzufügen",
            update: "Nachricht aktualisieren",
          },
        },
      },
    },
  },
  en: {
    translation: {
      app: {
        name: "ADM - Playbook",
      },
      sidebar: {
        consultations: "Consultations",
        chapters: "Chapters",
        overview: "Overview",
        categories: "Categories",
        news: "News",
      },
      errors: {
        notFound: "Error: Not Found",
        server: "Server Error",
        err404: "404: The page you are looking for isn’t here",
        err500: "500: Internal Server Error",
        shady: `
		You either tried some shady route or you came here by mistake.
		Whichever it is, try using the navigation.
		`,
      },
      consultations: {
        page: "Consultations",
        hello: "Good Morning, {{name}}",
        table: {
          fields: {
            date: {
              name: "Date",
              description: "Date of the appointment.",
            },
            consultant: {
              name: "Consultant",
              description: "Consultant E-Mail.",
            },
            customer: {
              name: "Customer",
              description: "Customer E-Mail.",
            },
            company: {
              name: "Company",
              description: "Company of the Customer.",
            },
            rating: {
              name: "Rating",
              description: "Rating of the consultation.",
            },
            action: {
              name: "eDialog",
              description: "eDialog URL.",
            },
          },
        },
      },
      consultationDetail: {
        page: "Consultation Details",
        tabs: {
          consultation: "Consultation",
          chapters: "Chapters",
          consultant: "Consultant",
          customer: "Customer",
        },
      },
      backToHome: "Back to Home",
      login: "Login",
      loginMsg: "",
      logout: "Logout",
      actions: {
        open: "Open",
        close: "Close",
        remove: "Remove",
        edit: "Edit",
        update: "Update",
        yes: "Yes",
        no: "No",
        abort: "Abort",
      },
      components: {
        contactDetails: {
          cardName: "Contact Details",
          email: "E-Mail",
          firstname: "Firstname",
          lastname: "Lastname",
          salutation: "Salutation",
          company: "Company",
        },
        consultingDetails: {
          name: "Consulting",
          appointment: "Appointment",
          appointmentPlace: "Place",
          nextAppointment: "Next Appointment",
          nextAppointmentPlace: "Next Place",
          topic: "Topic",
          rating: "Rating",
        },
        chapterList: {
          page: {
            name: "Chapter",
          },
          table: {
            id: {
              name: "Chapter ID",
              description: "Chapter ID",
            },
            name: {
              name: "Name",
              description: "Chapter Name",
            },
            key: {
              name: "Key",
              description: "Chapter key",
            },
            enabled: {
              name: "Enabled",
              description: "Chapter enabled or not",
            },
            path: {
              name: "Path",
              description: "Path of the chapter",
            },
            action: {
              name: "Action",
              description: "Remove chapter",
            },
          },
          form: {
            name: {
              name: "Chapter name",
              description: "Chapter Name",
              required: "Chapter Name is required",
            },
            path: {
              name: "Path to Chapter",
              required: "Path is required",
              error: "Enter a valid path",
            },
            key: {
              name: "Chapter key",
              required: "Key is required",
              error: "Enter valid key",
            },
            enabled: {
              name: "Chapter enabled",
            },
            category: {
              name: "Category of chapter",
              required: "Category is required",
              error: "Choose category",
            },
            add: "Add Chapter",
          },
        },
        settingsDrawer: {
          name: "Settings",
          textDirection: "Change text direction",
          rtl: "RTL",
          ltr: "LTR",
          responsiveFontSizes: "Responsive font sizes",
          adjustForSmallDevices: "Adjust font for small devices",
          compact: "Compact",
          fixedWidth: "Fixed width on some screens",
          roundCorners: "Rounded Corners",
          increaseBorderRadius: "Increase border radius",
          save: "Save Settings",
        },
        categoriesList: {
          page: {
            name: "Categories",
          },
          table: {
            id: {
              name: "Category ID",
              description: "Category ID",
            },
            name: {
              name: "Name",
              description: "Category Name",
            },
            key: {
              name: "Key",
              description: "Category Key",
            },
            action: {
              name: "Action",
              description: "Remove Category",
            },
          },
          form: {
            name: {
              name: "Category Name",
              description: "Category Name",
              required: "Category Name is required",
            },
            key: {
              name: "Category Key",
              required: "Keyis required",
              error: "Enter valid key",
            },
            add: "Add Category",
          },
        },
        newsList: {
          page: {
            name: "News",
          },
          table: {
            id: {
              name: "News ID",
              description: "News ID",
            },
            author: {
              name: "Author",
              description: "News author",
            },
            title: {
              name: "Title",
              description: "News title",
            },
            created_at: {
              name: "Created at",
              description: "Date of creation",
            },
            action: {
              name: "Action",
              description: "Actions",
            },
          },
          form: {
            author: {
              name: "News author",
              required: "News author is required",
            },
            title: {
              name: "Title",
              required: "Title is required",
            },
            content: {
              name: "Content",
              required: "Content is required",
              placeholder: "Write news...",
            },
            add: "Add message",
            update: "Update message",
          },
        },
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "de",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});
