class AuthApi {
  async fetchJwtCookie(username, password) {
    let xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.open(
        "POST",
        process.env.REACT_APP_MYBUILDINGS_API + "user/access-token",
        true
      );
      const buf = new Buffer.from(username + ":" + password);
      xhr.setRequestHeader("Authorization", "Basic " + buf.toString("base64"));
      xhr.onload = function () {
        if (this.status === 201) {
          resolve(xhr.responseText);
        } else {
          resolve(null);
        }
      };
      xhr.send();
    });
  }

  async login({ username, password }) {
    const token = await this.fetchJwtCookie(username, password);
    return new Promise((resolve, reject) => {
      try {
        if (token === null) {
          reject(new Error("Please check your username and password"));
          return;
        }

        resolve(token);
      } catch (err) {
        console.error("[Auth Api]: ", err);
        reject(new Error("Internal server error"));
      }
    });
  }

  async me(accessToken) {
    let xhr = new XMLHttpRequest();
    return new Promise((resolve, reject) => {
      xhr.open("GET", process.env.REACT_APP_API + "api/bje/user", true);
      xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
      xhr.onload = function () {
        if (this.status === 200) {
          const user = JSON.parse(this.responseText);

          console.log(user)

          if (!user.roles.includes("ROLE_SSO_PLAYBOOK_ADMIN") && !user.email.includes('@qmarketing.de')) {
            reject(new Error("Invalid user."));
          }

          global.API.authentications.MyBJE.apiKey = `Bearer ${accessToken}`;

          resolve({
            email: user.email,
            username: user.username,
            firstname: user.firstname,
            lastname: user.lastname,
            gender_iso5218: user.gender_iso5218,
            country: user.country,
            roles: user.roles,
          });
        } else {
          reject(new Error("Invalid authorization token"));
        }
      };
      xhr.send();
    });
  }
}

export const authApi = new AuthApi();
